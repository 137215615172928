<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
      <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
      </div>
    </v-row><br><br><br />

    <v-card class="card">
      <div v-if="init_loading">
        <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-card-title class="heading justify-center"> Issue Book</v-card-title>

        <div class="">
          <div class="add-section">
            <v-row align="center" justify="center">
              <!-- <v-col sm="1"></v-col> -->
              <v-col sm="4">
                <label style="font-weight: bold; color: blue">Select Member</label>
                <v-combobox class="text" v-model="selecteduser" :items="empmemberlist" item-text="displayname"
                  item-value="username" outlined dense :rules="[rules.required]" clearable required
                  @click="isempselected = false" @change="set1(selecteduser, 'emp')"></v-combobox>
              </v-col>
              <v-col sm="3">
                <label style="font-weight: bold; color: blue">For Barcode/RFID/QRCode</label><v-text-field outlined
                  v-model="prn" dense class="text" @keypress.enter="findUserInfo(selecteduser, false)"
                  placeholder="Enter PRN/EMPID"></v-text-field></v-col>
              <v-col sm="3">
                <v-checkbox class="pr-3" v-model="isbackdated" label="Is back dated entry " dense hide-details
                  @click="allowbackdate()"></v-checkbox>
                <v-menu v-if="backdated == true" v-model="showPicker6" :close-on-content-click="false"
                  transition="scale-transition" offset-y dense class="text" full-width max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="issue_backdate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint
                      dense class="text" readonly outlined v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="issue_backdate" no-title @input="showPicker6 = false"></v-date-picker>
                </v-menu>
                <v-menu v-else v-model="showPicker6" :close-on-content-click="false" transition="scale-transition"
                  offset-y dense full-width max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="issue_backdate" placeholder="Select Date" hint="YYYY/MM/DD" persistent-hint
                      dense readonly outlined v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="issue_backdate" no-title disabled @input="showPicker6 = false"></v-date-picker>
                </v-menu>

              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col xl="4" md="4" sm="4">
                <v-btn :disabled="!isempselected" class="primary" @click="findUserInfo(selecteduser, false)">Fetch
                  Details</v-btn>
              </v-col>
              <v-col xl="4" md="4" sm="4">
                <v-btn :disabled="!isempselected" class="primary" @click="getemployeehistory(selecteduser, false)">Get
                  History</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-dialog v-model="isemphistory">
          <v-card v-if="employeehistory.length != 0" class="pa-4">
            <span class="pa-3">
              Name:<b class="pr-10"> {{ employeehistoryname }}</b> Username:
              <b>{{ employeehistoryuser }}</b>
            </span>
            <v-col class="text-right">
              <v-btn icon dark @click="isemphistory = false">
                <v-icon style="color: black">mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-data-table v-if="employeehistory.length != 0" :headers="employehistoryheader" :items="employeehistory"
              class="elevation-1 nowrap" :search="search">
              <template v-slot:item.iscurrent="{ item }">
                <v-icon class="green--text" v-if="item.iscurrent">mdi mdi-check-circle-outline</v-icon>
              </template>


            </v-data-table>
          </v-card>
        </v-dialog>
        <div v-if="userInfo" class="cus-card">
          <v-row>
            <div style="width:80%">
              <v-row style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Reg/Emp Code</label></v-col>
                <v-col style="padding: 0; margin: 0"><span>: {{ empmemberinfo.reg_no }}</span></v-col>
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Is Active
                  </label></v-col>
                <v-col style="padding: 0; margin: 0"><span v-if="empmemberinfo.isoutoforg == true">: Yes</span>
                  <span v-else>: No</span>
                  <!--<v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo,'isoutoforg')"
                >mdi mdi-pencil-box</v-icon>
                        <template>
          <div class="text-center">
            <v-dialog v-model="dialogIspass" width="500">
              <v-card>
                <v-card-text style="padding-bottom: 0px">               
                    <div style="text-align: center">
                    <v-col>
                      <v-form ref="form">						  
                      <v-row>
                          
                          <v-col lg=8>
                           <v-checkbox
                                style="padding-top: 30px"
                                v-model="isoutoforg"
                                label="Is Out Of Organization?"
                                color="black"
                                dense
                                hide-details
                              ></v-checkbox>
                          </v-col>
                      </v-row>          
                      </v-form>
                    </v-col>
                    </div>       
                </v-card-text>
				      <div class="text-center">                  
                  <v-btn class="m-3" @click="dialogIspass = false"> Close </v-btn>
                  <v-btn class="m-3 green white--text" @click="saveEditMembar('isoutoforg')">Update</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
      </template>-->
                  <router-link target="_blank" class="bookItem" :to="{ name: 'useractive_inactive' }"
                    style="text-decoration: none;">
                    <v-icon class="blue--text pl-2"> mdi-open-in-new</v-icon>
                  </router-link>
                </v-col>






                <!-- <v-col style="padding: 0; margin: 0" lg="2">
              <label class="c-label">Edit </label></v-col
            >
            <v-col style="padding: 0; margin: 0"
              ><span>
              <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo)"
                >mdi mdi-pencil-box</v-icon
              > -->


                <!--------EDIT MEMBER -->
                <!-- <template>
          <div class="text-center">
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="grey lighten-2">
                  Edit Member
                </v-card-title>
                <v-card-text style="padding-bottom: 0px">
                
          <div style="text-align: center">
            <v-col>
              <v-form ref="form">
               <v-row>
              <v-col lg=4>
                <label class="add-text">Registration No:</label></v-col>
                <v-col sm=8>
                <v-text-field
                  class="text" v-model="editRegno" outlined dense readonly required
                ></v-text-field>
                </v-col></v-row>
              <v-row>
              <v-col lg=4>
                <label class="add-text">Name</label></v-col>
                <v-col sm=8>
                <v-text-field
                  class="text" v-model="editmembername" outlined dense clearable required
                ></v-text-field>
                </v-col></v-row>
                <v-row>
                 <v-col lg=4>
                <label class="add-text">Contact No</label></v-col>
                <v-col lg=8>
                <v-text-field
                  class="text" v-model="editmemberCont" outlined dense clearable required
                ></v-text-field>
                </v-col></v-row>
                <v-row>
                 <v-col lg=4>
                <label class="add-text">Email</label></v-col>
                <v-col lg=8>
                <v-text-field
                  class="text" v-model="editmemberEmail" outlined dense clearable required
                ></v-text-field>
                </v-col></v-row>
                 <v-row v-if="empmemberinfo.usertype != 'Employee'">
                 <v-col lg=4>
                <label class="add-text">Division</label></v-col>
                <v-col lg=8>
                <v-text-field v-if="!empmemberinfo.ld"
                  class="text"  v-model="editmemberDivision" disabled outlined dense
                ></v-text-field>
                <v-text-field v-else
                  class="text" v-model="editmemberDivision" outlined dense clearable required
                ></v-text-field>
                </v-col></v-row>
                 <v-row v-if="empmemberinfo.usertype != 'Employee'">
                 <v-col lg=4 >
                <label class="add-text">Roll No</label></v-col>
                
                <v-col lg=8 v-if="!empmemberinfo.ld">
                <v-text-field
                  class="text" v-model="editmemberRoll" disabled outlined dense
                ></v-text-field></v-col>
                <v-col lg=8 v-else>
                <v-text-field
                  class="text"  v-model="editmemberRoll" outlined dense clearable required
                ></v-text-field>
                </v-col></v-row>
                 <v-row v-if="empmemberinfo.usertype != 'Employee'">
                 <v-col lg=4>
                <label class="add-text">Membership Date</label></v-col>
                <v-col lg=8>              
                   <input
                              type="date"
                              v-model="editMembershipDate"
                            />                
                 </v-col></v-row>
                 <v-row>
                 <v-col lg=4>
                <label class="add-text">Checked Out Books</label></v-col>
                <v-col lg=8>
                <v-text-field
                  class="text" v-model="editTotalBooks" outlined dense clearable required
                ></v-text-field>
                </v-col></v-row>
                <v-row>
                 <v-col lg=4>
                <label class="add-text">Program</label></v-col>
                <v-col lg=8>
                 <v-autocomplete
                  item-text="pname"
                  item-value="pid" 
                 :items="programList" 
                  v-model="program" dense outlined>
                  </v-autocomplete>
                </v-col></v-row>
                 <v-row v-if="empmemberinfo.usertype != 'Employee'">
                 <v-col lg=4>
                <label class="add-text">Year</label></v-col>
                <v-col lg=8>
                 <v-autocomplete v-if="!empmemberinfo.ld"
                  item-text="yname"
                  item-value="yid" 
                 :items="yearList" 
                  readonly
                  v-model="year" dense outlined>
                  </v-autocomplete>
                <v-autocomplete v-else
                  item-text="yname"
                  item-value="yid" 
                 :items="yearList" 
                  v-model="year" dense outlined>
                  </v-autocomplete>
               
                </v-col>
               
                </v-row>
                <v-row v-if="!empmemberinfo.ld">
                 <v-col lg=3></v-col>
                 <center style="color:red">*Please Fill Missing Information In ERP*</center></v-row>
                
              
              </v-form>
            </v-col>
          </div>
        
                </v-card-text>-->

                <!-- <v-divider></v-divider> -->

                <!--   <div class="text-center">
                  <v-spacer></v-spacer>
                  <v-btn class="m-3" @click="dialog = false"> Close </v-btn>
                  <v-btn class="m-3 green white--text" @click="saveEditMembar()">Update</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
      </template> -->




                <!--------EDIT MEMBER END -->
                <!-- </span></v-col
            >-->
              </v-row>
              <v-row style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Name</label></v-col>
                <v-col style="padding: 0; margin: 0"><span>: {{ empmemberinfo.name }}</span>
                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'name')">mdi
                    mdi-pencil-box</v-icon>

                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogN" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=2>
                                      <label class="add-text">Name</label></v-col>
                                    <v-col sm=10>
                                      <v-text-field class="text" v-model="editmembername" outlined dense clearable
                                        required></v-text-field>
                                    </v-col></v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogN = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('name')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>

                </v-col>





                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Checked-Out Book</label></v-col>
                <v-col style="padding: 0; margin: 0"><span>: {{ empmemberinfo.total_checked_out_book }}/{{
                  empmemberinfo.max_number_book_allowed
                }}</span><v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'totalcheck')">mdi
                    mdi-pencil-box</v-icon>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogT" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Checked Out Books</label></v-col>
                                    <v-col lg=8>
                                      <v-text-field class="text" v-model="editTotalBooks" outlined dense clearable
                                        required></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogT = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('totalcheck')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Contact</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ empmemberinfo.mobile_number
                }}</span>
                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'contact')">mdi
                    mdi-pencil-box</v-icon>

                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogC" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Contact No</label></v-col>
                                    <v-col lg=8>
                                      <v-text-field class="text" v-model="editmemberCont" outlined dense clearable
                                        required></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>

                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogC = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('contact')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Email</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ empmemberinfo.email
                }}</span>
                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'email')">mdi
                    mdi-pencil-box</v-icon>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogE" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Email</label></v-col>
                                    <v-col lg=8>
                                      <v-text-field class="text" v-model="editmemberEmail" outlined dense clearable
                                        required></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogE = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('email')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">User Type</label></v-col>
                <v-col style="padding: 0; margin: 0"><span>: {{ empmemberinfo.usertype }}</span></v-col>

                <v-col v-if="empmemberinfo.program != ''" style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Program</label></v-col>
                <v-col v-if="empmemberinfo.program != ''" style="padding: 0; margin: 0"><span
                    style="padding: 0; margin: 0">: {{ empmemberinfo.program }}</span>
                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'program')">mdi
                    mdi-pencil-box</v-icon>

                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogP" width="500">
                        <v-card>
                          <v-card-title class="grey lighten-2">
                            Edit Member
                          </v-card-title>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Program</label></v-col>
                                    <v-col lg=8>
                                      <v-autocomplete item-text="pname" item-value="pid" :items="programList"
                                        v-model="program" dense outlined>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogP = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('program')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">
                <v-col v-if="empmemberinfo.rollno != ''" style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Roll No</label></v-col>
                <v-col v-if="empmemberinfo.rollno != ''" style="padding: 0; margin: 0"><span
                    style="padding: 0; margin: 0">: {{ empmemberinfo.rollno }}</span>

                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'rollno')">mdi
                    mdi-pencil-box</v-icon>

                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogR" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Roll No</label></v-col>
                                    <v-col lg=8 v-if="!empmemberinfo.ld">
                                      <v-text-field class="text" v-model="editmemberRoll" disabled outlined
                                        dense></v-text-field></v-col>
                                    <v-col lg=8 v-else>
                                      <v-text-field class="text" v-model="editmemberRoll" outlined dense clearable
                                        required></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                                <!-- <v-form ref="form">
                                  
                                  <v-row>
                                    <v-col lg=8 v-if="!empmemberinfo.ld">
                                      <v-text-field class="text" v-model="editmemberRoll" disabled outlined
                                        dense></v-text-field></v-col>
                                    <v-col lg=8 v-else>
                                      <v-text-field class="text" v-model="editmemberRoll" outlined dense clearable
                                        required></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form> -->
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogR = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('rollno')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>

                </v-col>
                <v-col v-if="empmemberinfo.division != ''" style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Division</label></v-col>
                <v-col v-if="empmemberinfo.division != ''" style="padding: 0; margin: 0"><span
                    style="padding: 0; margin: 0">: {{ empmemberinfo.division }}</span>
                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'division')">mdi
                    mdi-pencil-box</v-icon>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogD" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Division</label></v-col>
                                    <v-col lg=8>
                                      <v-text-field v-if="!empmemberinfo.ld" class="text" v-model="editmemberDivision"
                                        disabled outlined dense></v-text-field>
                                      <v-text-field v-else class="text" v-model="editmemberDivision" outlined dense
                                        clearable required></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogD = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('division')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">
                <v-col v-if="empmemberinfo.year" style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Year/STD</label></v-col>
                <v-col v-if="empmemberinfo.year" style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{
                  empmemberinfo.year }}</span>
                  <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'year')">mdi
                    mdi-pencil-box</v-icon>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogY" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row v-if="empmemberinfo.usertype != 'Employee'">
                                    <v-col lg=4>
                                      <label class="add-text">Year</label></v-col>
                                    <v-col lg=8>
                                      <v-autocomplete v-if="!empmemberinfo.ld" item-text="yname" item-value="yid"
                                        :items="yearList" readonly v-model="year" dense outlined>
                                      </v-autocomplete>
                                      <v-autocomplete v-else item-text="yname" item-value="yid" :items="yearList"
                                        v-model="year" dense outlined>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogY = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('year')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
                <v-col v-if="empmemberinfo.usertype != 'Employee'" style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Date Membership</label></v-col>
                <v-col v-if="empmemberinfo.usertype != 'Employee'" style="padding: 0; margin: 0"><span>:
                    <span v-if="empmemberinfo.date_of_membership != null && empmemberinfo.date_of_membership != ''">
                      {{
                        empmemberinfo.date_of_membership.split("T")[0].split("-")[2] +
                        "-" +
                        empmemberinfo.date_of_membership.split("T")[0].split("-")[1] +
                        "-" +
                        empmemberinfo.date_of_membership.split("T")[0].split("-")[0]
                      }}
                    </span>
                  </span> <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'membershipdate')">mdi
                    mdi-pencil-box</v-icon>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialogMD" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row v-if="empmemberinfo.usertype != 'Employee'">
                                    <v-col lg=4>
                                      <label class="add-text">Membership Date</label></v-col>
                                    <v-col lg=8>
                                      <input type="date" v-model="editMembershipDate" />
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialogMD = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('membershipdate')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>
                <!--///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label class="c-label">Policy Type</label></v-col>
                <v-col v-if="empmemberinfo" style="padding: 0; margin: 0"><span>:
                    {{ empmemberinfo == null ? "" : empmemberinfo.policy_type_name }}
                  </span> <v-icon v-if="empmemberinfo" class="mr-2" @click="updateay(empmemberinfo, 'policy_type')">mdi
                    mdi-pencil-box</v-icon>
                  <template>
                    <div class="text-center">
                      <v-dialog v-model="dialog_policy_type" width="500">
                        <v-card>
                          <v-card-text style="padding-bottom: 0px">
                            <div style="text-align: center">
                              <v-col>
                                <v-form ref="form">
                                  <v-row>
                                    <v-col lg=4>
                                      <label class="add-text">Select Policy Type</label></v-col>
                                    <v-col lg=8>
                                      <v-select outlined :items="policy_type_list" item-text="name" item-value="id"
                                        v-model="selected_policy_type"></v-select>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-col>
                            </div>
                          </v-card-text>
                          <div class="text-center">
                            <v-btn class="m-3" @click="dialog_policy_type = false"> Close </v-btn>
                            <v-btn class="m-3 green white--text" @click="saveEditMembar('policy_type')">Update</v-btn>
                          </div>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                </v-col>

              </v-row>
            </div>
            <div>
              <v-img height="150" width="150" :src="url">
                <template v-slot:placeholder>
                  <v-icon size="150">mdi mdi-account</v-icon>
                </template>
              </v-img>
            </div>
          </v-row>
        </div>
        <v-data-table v-if="bookreservationinformation.length !== 0" :headers="headers1"
          :items="bookreservationinformation" class="elevation-1 nowrap" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <span style="font-weight: bold; color: blue">Reserved Book Details</span><v-spacer></v-spacer>
              <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details>
              </v-text-field>
              <span style="width: 20px"></span>
            </v-toolbar>
          </template>

          <template v-slot:item.issue="{ item }">
            <v-checkbox v-if="item.book_status == 'Available'" v-model="reserve_book" :value="item.id"
              @change="set_reserv_book(item.barcode)"></v-checkbox>
            <div v-else>{{ item.book_status }}</div>
          </template>
        </v-data-table>

        <center v-if="fineRecord.length > 0">
          <p style="color: red; font-weight: bold; font-size: 22px">
            Old Fine Record
          </p>
        </center>

        <v-simple-table v-if="fineRecord.length > 0" class="m-3" style="border: 1px solid">
          <template v-slot:default>
            <thead>
              <tr style="background: lightgray">
                <th class="text-left" style="font-size: 16px">Book Title</th>
                <th class="text-left" style="font-size: 16px">
                  Accession Number
                </th>
                <th class="text-left" style="font-size: 16px"> Fine</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(trackdata, acc_no) in fineRecord" :key="acc_no">
                <td>{{ trackdata.title }}</td>
                <td>{{ trackdata.acc_no }}</td>
                <td style="color: red; font-weight: bold; font-size: 18px">
                  {{ trackdata.fine }}
                </td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="fineRecord.length > 0">
          <p style="color: red; font-style: italic; font-size: 12px" class="ml-3">
            *Pay remaining old fine first for issue new book.
          </p>
        </div>

        <center v-if="userInfo">
          <v-row align="center" justify="center">
            <v-col sm="1"> </v-col>
            <v-col sm="4">
              <!--<label style="font-weight: bold; color: blue"
                >Select Book Accession Number</label
              >-->
              <v-row class="pa-2" justify="center">
                <v-checkbox class="pr-3" v-model="seaechbyacc1" label="Search By Accession No" dense hide-details
                  @click="group('one')"></v-checkbox>
                <v-checkbox v-model="seaechbyacc2" label="Search By Title" @click="group('two')" dense
                  hide-details></v-checkbox>
              </v-row>
              <v-combobox style="padding: 0px; margin: 0px" v-model="selectedbookitem" :items="allBookInfo"
                item-text="displayname" placeholder="Select Book" outlined return-object :disabled="bookLimit" dense
                :rules="[rules.required]" clearable required @input.native="onload1($event.srcElement.value)"
                @click="isbookselected = false" @change="set1(selectedbookitem, 'book')"></v-combobox>
            </v-col>
            <v-col sm="3">
              <label style="font-weight: bold; color: blue">For Barcode/RFID/QRCode </label><v-text-field outlined
                v-model="accno" dense class="text" placeholder="Enter Accection NO" :disabled="bookLimit"
                @input="set1('', 'bookbarcode')"
                @keypress.enter="findBookItemInfo(selectedbookitem, false)"></v-text-field></v-col>

            <v-btn :disabled="!isbookselected && (bookLimit)" class="primary"
              @click="findBookItemInfo(selectedbookitem, false)">Fetch Details</v-btn>

            <v-col sm="3"></v-col>
          </v-row>
        </center>

        <div v-if="bookInfo" class="cus-card">
          <v-row>
            <div style="width:80%">
              <v-row style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2" md="2">
                  <label style="padding: 0; margin: 0" class="c-label">Title</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.title
                }}</span></v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Author(s)</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.author
                }}</span></v-col>
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Publisher</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.publisher
                }}</span></v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">

                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Book Type</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.booktype
                }}</span></v-col>
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Book Format</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.bookformat
                }}</span></v-col>
              </v-row>
              <v-row no-gutters style="padding: 0; margin: 0">
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0;" class=" c-label">Classification No</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.cno
                }}</span></v-col>
                <v-col style="padding: 0; margin: 0;" lg="2">
                  <label style="padding: 0; margin: 0;" class="c-label">Book Price</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.bookprice
                }}</span></v-col>
              </v-row>
              <v-row style="padding: 0; margin: 0">

                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">No of copies available</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.total_books
                }}</span></v-col>
                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Category</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: {{ bookinformation.bookcategory
                }}</span></v-col>

              </v-row>
              <v-row style="padding: 0; margin: 0">


                <v-col style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Is Donated</label></v-col>
                <v-col style="padding: 0; margin: 0"><span style="padding: 0; margin: 0">: <span
                      v-if="bookinformation.is_received_from_donation"> YES </span><span v-else>NO</span>
                  </span></v-col>
                <v-col v-if="bookinformation.is_received_from_donation" style="padding: 0; margin: 0" lg="2">
                  <label style="padding: 0; margin: 0" class="c-label">Donor Name</label></v-col>
                <v-col v-if="bookinformation.is_received_from_donation" style="padding: 0; margin: 0"><span
                    style="padding: 0; margin: 0">: {{ bookinformation.book_donar_name }}
                  </span></v-col>

              </v-row>
            </div>
            <div>
              <v-img v-if='bookinformation.is_img' height="150" width="150" :src="image_url">
                <template v-slot:placeholder>
                  <v-icon size="150">mdi mdi-book</v-icon>
                </template>
              </v-img>
              <center v-else> <v-btn @click="book_upload_dialog = true"
                  class="primary">Photo<v-icon>mdi-upload</v-icon></v-btn>
                <v-dialog width="300" large transition v-model="book_upload_dialog">


                  <v-card>
                    <v-card-title> Select Book Cover Image </v-card-title>
                    <v-card-text> <v-file-input class="ma-5" label="Select File" single-line counter
                        v-model="file"></v-file-input></v-card-text>
                    <v-card-actions>
                      <center><v-btn @click="savephoto(bookinformation.accession_number)"
                          class="primary ma-3">Photo<v-icon>mdi-upload</v-icon></v-btn></center>
                    </v-card-actions>

                  </v-card>
                </v-dialog>
              </center>
            </div>
          </v-row>
        </div>
        <div class="pa-5 ma-5 grey lighten-3" v-if="Array.isArray(bookhistory) && bookhistory.length">
          <h3>Book History</h3>
          <br />
          <v-simple-table class="grey lighten-3">
            <thead>
              <th align="center">Emp no/Prn no</th>
              <th align="center">name</th>
              <th align="center">username</th>
              <th align="center">return date</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in bookhistory" :key="index">
                <td>{{ item.grno_empid }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.username }}</td>
                <td>
                  {{
                    item.return_date.split("T")[0].split("-")[2] +
                    "-" +
                    item.return_date.split("T")[0].split("-")[1] +
                    "-" +
                    item.return_date.split("T")[0].split("-")[0]
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <center v-if="bookInfo">
          <v-btn class="m-2" v-if="due_date_manually" color="primary" @click="open_due_date_dailog">
            Issue Book
          </v-btn>
          <!-- <v-btn class="m-2" v-else color="primary" @click="issueBook"> -->
            <v-btn class="m-2" v-else @click="issueBook" color="primary">
            Issue Book
          </v-btn>
        </center>

        <center></center>
      </div>
      <v-dialog v-model="due_date_dailog" width="700">
          <v-card>
            <v-card-title>Due Date For Book</v-card-title>
            <v-card-text>
              <v-text-field type="date" v-model="selected_due_date" outlined >
                <template #label>
                    Select Due Date <span style="color:red;">*</span>
                  </template>
              </v-text-field>
              <v-card-actions>
                <!-- <v-btn class="m-2" v-else color="primary" > -->
                <v-btn class="m-2" color="primary" @click="issueBook">
                  Issue Book
                </v-btn>
              </v-card-actions>
            </v-card-text>
            </v-card>
      </v-dialog>
      <!-- <v-card-text> -->
      <!-- <v-data-table :headers="headers" :items="empmemberbooklendinginfo" class="elevation-1 nowrap" :search="search"> </v-data-table> -->
      <v-data-table v-if="empmemberbooklendinginfo.length !== 0" :headers="headers" :items="empmemberbooklendinginfo"
        class="elevation-1 nowrap" :search="search">

        <template v-slot:item.due_date="{ item }">
          {{ item.due_date }}
          <!-- <div v-if="item.due_date != '' && item.due_date != 'NA'">
            {{item.due_date.split("T")[0].split("-")[2]+"-"+item.due_date.split("T")[0].split("-")[1]+"-"+item.due_date.split("T")[0].split("-")[0]}}
         </div> -->
        </template>
        <template v-slot:item.action="{ item }">
          <div>
            <v-tooltip color="blue" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="blue--text pa-2" @click="renewBook(item)">mdi
                  mdi-book-refresh</v-icon>
              </template>
              <span>Renew Book</span>
            </v-tooltip>
            <v-tooltip color="orange" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="orange--text pa-2" @click="receiveBook(item)">mdi
                  mdi-book-arrow-left</v-icon>
              </template>
              <span>Receive Book</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:top>
          <v-toolbar flat color="white">
            <span style="font-weight: bold; color: blue">Issued Book Details</span><v-spacer></v-spacer>
            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details>
            </v-text-field>
            <span style="width: 20px"></span>
          </v-toolbar>
        </template>

      </v-data-table>
      <!-- </v-card-text> -->
    </v-card>

    <!-- <v-row align="center">
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="6"
                            >
                            <v-combobox v-model="selecteduser" :items="empmemberlist" item-text="username" outlined return-object dense :rules="[rules.required]" clearable required  @change="findUserInfo(selecteduser)" ></v-combobox>
                            </v-col>
                        </v-row>
                        <template>
                <v-card
                    class="mx-auto"
                    max-width="600"
                >
                    <v-card-text>
                         <v-row>
                                <v-col cols="6">
                                    {{empmemberinfo.user}}
                                </v-col>
                                 <v-col cols="6">
                                    {{empmemberinfo.total_checked_out_book}}
                                </v-col>
                        </v-row>
                        <v-row>
                                <v-col cols="6">
                                    {{empmemberinfo.usertype}}
                                </v-col>
                                 <v-col cols="6">
                                    {{empmemberinfo.date_of_membership}}
                                </v-col>
                        </v-row>
                         <v-row>
                                <v-col cols="6">
                                    {{empmemberinfo.mobile_number}}
                                </v-col>
                                 <v-col cols="6">
                                    {{empmemberinfo.email}}
                                </v-col>
                        </v-row>
                        <v-row>
                                <v-col cols="6">
                                    {{empmemberinfo.address}}
                                </v-col>
                                 <v-col cols="6">
                                    {{empmemberinfo.city}}
                                </v-col>
                        </v-row>
                         <v-row>
                                <v-col cols="6">
                                    {{empmemberinfo.state}}
                                </v-col>
                                 <v-col cols="6">
                                    {{empmemberinfo.country}}
                                </v-col>
                        </v-row>
                        <v-row>
                                <v-col cols="6">
                                    {{empmemberinfo.pin}}
                                </v-col>
                                
                        </v-row>
                    </v-card-text>
                    
                </v-card>
                
            </template>

             
             <v-row align="center">
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="6"
                            >
                            <v-combobox v-model="selectedbookitem" :items="allBookInfo" item-text="accession_number" outlined return-object dense :rules="[rules.required]" clearable required @change="findBookItemInfo(selectedbookitem)"></v-combobox>
                            </v-col>
                        </v-row>
            <template>
                <v-card
                    class="mx-auto"
                    max-width="600"
                >
                    <v-card-text>
                         <v-row>
                                <v-col cols="6">
                                    {{bookinformation.title}}
                                </v-col>
                                 <v-col cols="6">
                                    {{bookinformation.author}}
                                </v-col>
                        </v-row>
                        <v-row>
                                <v-col cols="6">
                                    {{bookinformation.bookcategory}}
                                </v-col>
                                 <v-col cols="6">
                                    {{bookinformation.publisher}}
                                </v-col>
                        </v-row>
                         <v-row>
                                <v-col cols="6">
                                    {{bookinformation.booktype}}
                                </v-col>
                                 <v-col cols="6">
                                    {{bookinformation.bookformat}}
                                </v-col>
                        </v-row>
                        <v-row>
                                <v-col cols="6">
                                    {{bookinformation.bookprice}}
                                </v-col>
                                 <v-col cols="6">
                                    {{bookinformation.total_books}}
                                </v-col>
                        </v-row>
                         <v-row>
                                <v-col cols="6">
                                    {{bookinformation.member}}
                                </v-col>
                                
                        </v-row>
                        
                    </v-card-text>
                    
                </v-card>
                
            </template>
            <br>
            <v-layout align-center justify-center >
                <v-btn class="mr-4" color="primary" @click="issueBook">
                Issue Book
                </v-btn>
            </v-layout>
             <v-data-table :headers="headers" :items="empmemberbooklendinginfo" class="elevation-1 nowrap" :search="search">

             </v-data-table> -->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    showPicker6: false,
    policy_type_list: [],
    programList: [],
    dialogN: false,
    dialogC: false,
    dialogD: false,
    dialogE: false,
    dialogMD: false,
    dialogP: false,
    dialogR: false,
    dialogT: false,
    dialogY: false,
    dialogIspass: false,
    issue_backdate: "",
    backdated: false,
    isbackdated: false,
    menu2: false,
    renew_dialog: false,
    return_dialog: false,
    valid: false,
    init_loading: false,
    isempselected: true,
    isbookselected: false,
    userid: "",
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    book_upload_dialog: false,
    snackbar_msg: "",
    bookLimit: false,
    color: "",
    snackbar: false,
    search: "",
    reserve_book: null,
    empmemberlist: [],
    empmemberinfo: [],
    bookinformation: [],
    empmemberbooklendinginfo: [],
    bookreservationinformation: [],
    allBookInfo: [],
    selecteduser: "",
    selectedbookitem: "",
    org: "",
    inSave: true,
    dialog: false,
    prn: "",
    accno: "",
    date: null,
    employeehistoryuser: "",
    employeehistoryname: "",
    isemphistory: false,
    due_date_manually:false,
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Action",
        align: "center",
        sortable: true,
        value: "action",
      },

      {
        text: "Accession Number",
        align: "left",
        sortable: true,
        value: "accession_number",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },

      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Book Type",
        align: "left",
        sortable: true,
        value: "booktype",
      },
      {
        text: "Book Format",
        align: "left",
        sortable: true,
        value: "bookformat",
      },
      {
        text: "Issue Date",
        align: "left",
        sortable: true,
        value: "borrowed_date",
      },
      {
        text: "Due Date",
        align: "left",
        sortable: true,
        value: "due_date",
      },
      {
        text: "Fine",
        align: "left",
        sortable: true,
        value: "fine",
      },

      // { text: "Actions", value: "action", sortable: false },
      // { text: "Active", value: "edit", sortable: false },
    ],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Issue Books',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
    headers1: [
      {
        text: "Sr no",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession No",
        sortable: true,
        value: "acc_no",
      },
      {
        text: "Barcode",
        sortable: true,
        value: "barcode",
      },
      {
        text: "ISBN",
        sortable: true,
        value: "isbn",
      },
      {
        text: "Title",
        sortable: true,
        value: "title",
      },
      // {
      //     text: "Author",
      //     align: "left",
      //     sortable: true,
      //     value: "author",
      // },

      {
        text: "Issue Book",
        sortable: true,
        value: "issue",
      },
      // {
      //     text: "Book Type",
      //     align: "left",
      //     sortable: true,
      //     value: "booktype",
      // },
      // {
      //     text: "Book Format",
      //     align: "left",
      //     sortable: true,
      //     value: "bookformat",
      // },
      // {
      //     text: "Borrowed Date",
      //     align: "left",
      //     sortable: true,
      //     value: "borrowed_date",
      // },
      // {
      //     text: "Due Date",
      //     align: "left",
      //     sortable: true,
      //     value: "due_date",
      // },
      // {
      //     text: "Fine",
      //     align: "left",
      //     sortable: true,
      //     value: "fine",
      // },

      // { text: "Actions", value: "action", sortable: false },
      // { text: "Active", value: "edit", sortable: false },
    ],

    employehistoryheader: [
      {
        text: "Sr no",
        sortable: true,
        value: "srno",
      },
      {
        text: "Accession No",
        sortable: true,
        value: "Assection_No",
      },
      {
        text: "Title",
        sortable: true,
        value: "title",
      },

      {
        text: "Issue Date",
        sortable: true,
        value: "borrowing_date",
      },
      {
        text: "Due Date",
        sortable: true,
        value: "due_date",
      },
      {
        text: "Return Date",
        sortable: true,
        value: "return_date",
      },
      {
        text: "is Current",
        sortable: true,
        value: "iscurrent",
      },
    ],
    editmembername: '',
    editmemberCont: '',
    editmemberEmail: '',
    editmemberDivision: '',
    editmemberRoll: '',
    editTotalBooks: '',
    editRegno: '',
    editMembershipDate: '',
    program: '',
    year: '',
    seaechbyacc: true,
    bookCategoryList: [],
    roleLink_list: [],
    role_list: [],
    userInfo: false,
    fineRecord: [],
    bookInfo: false,
    bookitemid: null,
    bookhistory: [],
    employeehistory: [],
    seaechbyacc2: false,
    seaechbyacc1: true,
    url: "",
    isphoto: false,
    image_url: "",
    file: null,
    dialog_policy_type: false,
    selected_policy_type: "",
    due_date_dailog:false,
    selected_due_date:"",
    // editedIndex: -1,
    // editedItem: {
    //     displayOrder:"",
    //     rackNo:"",
    //     blockNo:"",
    //     location:"",
    //     capacity:"",
    //     bookCategory:"",
    //     bookCategoryId:"",
    //     isactive: false,
    //     id:""
    // },
    // defaultItem: {
    //     isbn:'',
    //     title:'',
    //     description:'',
    //     authors:'',
    //     edition:'',
    //     volume:'',
    //     year:'',
    //     pages:'',
    //     copies:'',
    //     prize:'',
    //     billNo:'',
    //     isDonated:Boolean,

    //     isactive: false,
    //     id:""
    // },
  }),

  // computed: {
  //     formTitle() {
  //         return this.editedIndex === -1 ? "New Book" : "Edit Book";
  //     },
  // },

  watch: {
    // dialog(val) {
    //     val || this.close();
    // },
    // overlay(val) {
    //     val = () => {
    //         this.overlay = false;
    //     };
    // },
    date(val) {
      this.date = this.formatDate(this.date);
    }
  },
  mounted() {
    this.onLoad();
    this.getpolicy_type_list();
    // this.selecteduser = localStorage.getItem("EPS-uid");

    // var params = { userid: this.selecteduser };
    // this.findUserInfo(params, true);

    this.selectedbookitem = this.$route.params.assno;
    this.bookitemid = this.$route.params.id;
    //  var params={"userid":this.selecteduser}
    //     this.findUserInfo(params,true);

    if (this.bookitemid != undefined) {
      var params1 = { blfid: this.bookitemid };
      this.findBookItemInfo(params1, true);
    }
  },
  methods: {
    open_due_date_dailog()
    {
      this.due_date_dailog=true;
    },
    getpolicy_type_list() {
      axios
        .get("/Librarian/get_policy_type_list")
        .then((res) => {
          if (res.data.msg == "200") {
            this.policy_type_list = res.data.policy_list;        // this.onload1();
            this.overlay = false;
          }
        })


    },
    savephoto(value) {

      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("accession_number", value);
      axios.post("/Librarian/uploadbookphoto", formData).then((res) => {
        if (res.data.msg == "200") {
          this.showSnackbar("green", "Successfully Uploaded Photo.");
          this.image_url = res.data.url;

          this.book_upload_dialog = false;
          this.bookinformation.is_img = true;

        } else {
          this.showSnackbar("red", res.data.msg);

        }
      });
    },
    allowbackdate() {
      //  alert("in allow date"+ this.isbackdated);
      if (this.isbackdated == true) {
        this.backdated = true
      } else {
        this.issue_backdate = "";
      }
    },
    updateay(item, value) {

      //alert(item.name+"   "+item);
      if (value == 'name') {
        this.dialogN = true;
        this.editmembername = item.name;
      } else if (value == 'totalcheck') {
        this.dialogT = true;
        this.editTotalBooks = item.max_number_book_allowed;
      } else if (value == 'contact') {
        this.dialogC = true;
        this.editmemberCont = item.mobile_number;
      } else if (value == 'email') {
        this.dialogE = true;
        this.editmemberEmail = item.email;
      } else if (value == 'division') {
        this.dialogD = true;
        this.editmemberDivision = item.division;
      } else if (value == 'rollno') {
        this.dialogR = true;
        this.editmemberRoll = item.rollno;
      } else if (value == 'membershipdate') {
        this.dialogMD = true;
        this.editMembershipDate = item.date_of_membership.split("T")[0]
      } else if (value == 'program') {
        this.dialogP = true;
        this.program = item.pid;
      } else if (value == 'year') {
        this.dialogY = true;
        this.year = item.yid;
      } else if (value == 'isoutoforg') {
        this.dialogIspass = true;
        this.isoutoforg = item.isoutoforg
      }
      else if (value == 'policy_type') {
        this.dialog_policy_type = true;
        this.selected_policy_type = item.policy_type_id;
      }
      this.editRegno = item.reg_no;
    },
    updateayT(item) {
      this.dialogT = true;
      this.editTotalBooks = item.max_number_book_allowed;
    },
    updateayC(item) {
      this.dialogC = true;
      this.editmemberCont = item.mobile_number;
    },
    saveEditMembar(value) {
      // alert("in saveeditMember"+this.date);
      const data = {
        value: value,
        name: this.editmembername,
        contact: this.editmemberCont,
        email: this.editmemberEmail,
        div: this.editmemberDivision,
        rollNo: this.editmemberRoll,
        totalbooks: this.editTotalBooks,
        regNo: this.editRegno,
        date: this.editMembershipDate,
        pgm: this.program,
        year: this.year,
        isoutoforg: this.isoutoforg,
        policy_type: this.selected_policy_type
      };
      axios
        .post("/IssueBook/editMemberIssueBook", data)
        .then((res) => {
          //  alert(res.data.msg);
          if (res.data.msg == 'name') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogN = false;
          } else if (res.data.msg == 'contact') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogC = false;
          } else if (res.data.msg == 'email') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogE = false;
          } else if (res.data.msg == 'division') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogD = false;
          } else if (res.data.msg == 'rollno') {

            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogR = false;
          } else if (res.data.msg == 'program') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogP = false;
          } else if (res.data.msg == 'year') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogY = false;
          } else if (res.data.msg == 'date') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogMD = false;
          }
          else if (res.data.msg == 'totalbooks') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogMD = false;
          } else if (res.data.msg == 'isoutoforg') {
            this.showSnackbar("#4caf50", "Updated Successfully !!!");
            this.dialogIspass = false;
          }
          this.findUserInfo(this.selecteduser, false);
          this.dialogN = false;
          this.dialog_policy_type = false;
          this.dialogC = false;
          this.dialogD = false;
          this.dialogE = false;
          this.dialogMD = false;
          this.dialogP = false;
          this.dialogR = false;
          this.dialogT = false;
          this.dialogY = false;
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          window.console.log(error);
        });
    },
    renewBook(item) {
      if (confirm("Are You Sure You Want To Renew Book?")) {
        const data = {
          a_no: item.accession_number,
        };
        this.overlay = true;
        axios
          .post("/IssueBook/renewBook", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.showSnackbar("#4caf50", "Renew Book Successfully");
              this.overlay = false;
              this.findUserInfo(this.selecteduser, false);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.overlay = false;
          })
          .finally(() => { });
      }
    },
    receiveBook(item) {
      console.log(item);
      if (confirm("Are You Sure You Want To Return Book?")) {
        const data = {
          a_no: item.accession_number,
        };
        this.overlay = true;
        axios
          .post("/ReturnBook/receiveBook", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.showSnackbar("#4caf50", "Return Book Successfully");
              this.overlay = false;
              this.findUserInfo(this.selecteduser, false);
            }
            else {
              this.showSnackbar("#b71c1c", res.data.msg); // show snackbar

              this.overlay = false;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.overlay = false;
          })
          .finally(() => { });
      }
    },
    group(value) {
      if (value == "one") {
        this.seaechbyacc1 = true;
        this.seaechbyacc2 = false;
        this.seaechbyacc = true;
      }
      if (value == "two") {
        this.seaechbyacc2 = true;
        this.seaechbyacc1 = false;
        this.seaechbyacc = false;
      }
    },
    set1(value, person) {
      if (person == "emp") {
        this.prn = value.displayname.split(":")[0].trim();
        this.isempselected = true;
      } else if (person == "bookbarcode") {
        if (this.accno == "") this.isbookselected = false;
        else this.isbookselected = true;
      } else {
        this.accno = value.displayname.split(":")[1].trim();
        this.isbookselected = true;
      }
    },
    getemployeehistory(seluser, check) {
      // this.empmemberbooklendinginfo=[];
      this.bookInfo = false;
      var params = null;
      if (!check) params = { userid: this.selecteduser.username };
      else params = seluser;
      console.log(this.selecteduser);
      this.overlay = true;
      if (this.prn != "") {
        params = { userid: this.prn };
        axios.post("/IssueBook/getemployeehistorybyprn", params).then((res) => {
          this.overlay = false;
          if (res.data.msg == "200") {
            this.employeehistory = res.data.data;
            this.employeehistoryname = res.data.name;
            this.employeehistoryuser = res.data.user;
            this.isemphistory = true;
            //  this.userInfo = false;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
            // this.userInfo = false;
          }
        });
      } else {
        axios.post("/IssueBook/getemployeehistory", params).then((res) => {
          this.overlay = false;
          if (res.data.msg == "200") {
            this.employeehistory = res.data.data;
            this.employeehistoryname = res.data.name;
            this.employeehistoryuser = res.data.user;
            this.isemphistory = true;
            this.userInfo = false;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
            this.userInfo = false;
          }
        });
      }
    },
    findUserInfo(seluser, check) {
      this.bookLimit = false;
      var params = null;
      if (!check) params = { userid: this.selecteduser.username };
      else params = seluser;
      console.log(this.selecteduser);
      this.overlay = true;
      this.isemphistory = false;
      this.employeehistory = [];
      this.url = "";
      if (this.prn != "") {
        params = { userid: this.prn };
        axios
          .post("/IssueBook/getEmpMemInformationbyprn", params)
          .then((res) => {
            this.overlay = false;
            if (res.data.msg == "200") {
              this.userInfo = true;
              this.empmemberinfo = res.data.memberinfo;
              this.empmemberbooklendinginfo = res.data.booklendinginformation;
              this.bookreservationinformation =
                res.data.bookreservationinformation;
              this.fineRecord = res.data.fineRecord;
              this.selecteduser = res.data.memberinfo.user;
              if (
                this.empmemberinfo.max_number_book_allowed <=
                this.empmemberbooklendinginfo.length
              ) {
                this.bookLimit = true;
                if (this.bookLimit)
                  this.showSnackbar("#b71c1c", "Maximum Book limit exeed!!");
              }
              this.getimage(this.prn);
              localStorage.setItem("user_id", this.empmemberinfo.userid)
            } else {
              this.showSnackbar("red", res.data.msg);
              this.userInfo = false;
            }
          });
      } else {
        axios.post("/IssueBook/getEmpMemInformation", params).then((res) => {
          this.overlay = false;
          if (res.data.msg == "200") {
            this.userInfo = true;
            this.empmemberinfo = res.data.memberinfo;
            this.empmemberbooklendinginfo = res.data.booklendinginformation;
            this.bookreservationinformation =
              res.data.bookreservationinformation;
            this.fineRecord = res.data.fineRecord;
            this.selecteduser = res.data.memberinfo.user;
            if (
              this.empmemberinfo.max_number_book_allowed <=
              this.empmemberbooklendinginfo.length
            ) {
              this.bookLimit = true;
              if (this.bookLimit)
                this.showSnackbar("#b71c1c", "Maximum Book limit exeed!!");
            }
          } else {
            this.showSnackbar("red", res.data.msg);
            this.userInfo = false;
          }
        });
      }
      this.isemphistory = false;
    },
    getimage(value) {
      var params = { prn: value };
      axios
        .post("/ImportData/getphotfromerp", params)
        .then((res) => {
          if (res.data.msg == '200') {
            this.url = res.data.url;
            this.isphoto = res.data.isphoto;
          }


        });
    },
    get_book_image(value) {
      this.image_url = "";

      var params = { "acc_no": value };
      axios
        .post("/Librarian/getimageurl", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.image_url = res.data.url;

          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });

    },

    set_reserv_book(acc_no) {
      this.accno = acc_no;
      this.findBookItemInfo(acc_no);
    },

    findBookItemInfo(selectedbookitem) {
      // alert("1")
      this.bookhistory = [];
      this.overlay = true;
      if (this.accno != "") {
        // alert("2")
        var params = { blfid: this.accno };
        axios
          .post("/IssueBook/getBookItmeInformationbyaccno", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.bookInfo = true;
              this.bookinformation = res.data.bookinformation;
              this.bookhistory = res.data.bookhistory;

              this.bookitemid = res.data.bfid;
              this.get_book_image(this.accno);
              this.image_url = "",
                this.file = null;
              // this.empmemberbooklendinginfo = res.data.booklendinginformation;
            } else {
              this.showSnackbar("red", res.data.msg);
              this.bookInfo = false;
              this.overlay = false;
            }
          });
      } else {
        // alert("3")
        axios
          .post("/IssueBook/getBookItmeInformation", selectedbookitem)
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.bookInfo = true;
              this.bookinformation = res.data.bookinformation;
              this.bookhistory = res.data.bookhistory;
              console.log("dfngdh fghd  f fffffffffffffff");
              console.log(selectedbookitem);
              this.bookitemid = selectedbookitem.blfid;
              // this.empmemberbooklendinginfo = res.data.booklendinginformation;
            } else {
              this.showSnackbar("red", res.data.msg);
              this.bookInfo = false;
              this.overlay = false;
            }
          });
      }
    },
    getReserveBookA_no() {
      this.overlay = true;
      const data = {
        bookId: this.reserve_book,
      };
      if (this.reserve_book !== null) {
        axios
          .post("/IssueBook/getReserveBookA_no", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.allBookInfo = res.data.acc_noList;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var overlay = false;
          });
      } else {
        axios
          .post("/IssueBook/getBookA_no", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.overlay = false;
              this.allBookInfo = res.data.acc_noList;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var overlay = false;
          });
      }
    },
    issueBook() {
      if(this.due_date_manually)
      {
        if(this.selected_due_date=="")
        {
          this.showSnackbar("red", "Please Select Due Date..");
          return;
        }
      }
     
      const data = {
        userid: this.selecteduser,
        bookitemid: this.bookitemid,
        backdate: this.issue_backdate,
        selected_due_date:this.selected_due_date,
      };
      console.log(data);
      this.overlay = true;
      axios
        .post("/IssueBook/memberIssueBook", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.selectedbookitem = "";
            this.bookinformation = [];
            this.empmemberbooklendinginfo = res.data.booklendinginformation;
            // this.allBookInfo = res.data.allBookInfo;
            this.showSnackbar("#4caf50", "Successfully save data..."); // show snackbar
            // this.findUserInfo(this.selecteduser,true);
            this.reserve_book = null;
            this.due_date_dailog=false;
          } else {
            this.overlay = false;
            this.due_date_dailog=false;
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          this.due_date_dailog=false;
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    onLoad() {
      this.init_loading = true;
      axios
        .post("/IssueBook/getEmpMemData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.empmemberlist = res.data.userlist;
            this.due_date_manually=res.data.due_date_manually;
            this.init_loading = false,
              this.programList = res.data.programList;
            this.yearList = res.data.yearList;
            // this.onload1();
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    yash(value) {

    },
    onload1(value) {
      if (value != "") {
        var params = { text: value, seaechbyacc: this.seaechbyacc };
        axios
          .post("/IssueBook/getbooksinfo1", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var overlay = false;
          });
      }
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      this.editedItem.displayOrder = parseInt(this.editedItem.displayOrder);
      this.editedItem.capacity = parseInt(this.editedItem.capacity);
      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          axios
            .post("/Admin/editRack", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                //window.alert(res.data.msg)
                Object.assign(
                  this.roleLink_list[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar("#4caf50", "Rack Updated Successfully..."); // show snackbar on success
                //this.onLoad();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      } else {
        // this.roleLink_list.push(this.editedItem)
        axios
          .post("/Admin/saveRack", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.showSnackbar("#4caf50", "Rack Added Successfully!!!"); // show snackbar on success
              //this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, //save()

    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    }, // .....end of isValidated()
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}

.c-label {
  font-weight: bold;
}

.add-section {
  display: block;
  margin: 1rem;
}

.text>>>.v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}

.v-messages {
  display: none;
}
</style>
